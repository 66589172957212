import img1 from '../assets/s1.png';
import img2 from '../assets/s2.png';
import img3 from '../assets/s3.png';
import img4 from '../assets/s4.png';
import img5 from '../assets/s5.png';
import img6 from '../assets/s6.png';
import img7 from '../assets/s7.png';
import img8 from '../assets/s8.png';
import img9 from '../assets/s9.png';
import img10 from '../assets/s10.png';


export const SliderProducts = [
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '25',
        img: img1
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '30',
        img: img2
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '35',
        img: img3
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '40',
        img: img4
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '50',
        img: img5
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '65',
        img: img6
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '29',
        img: img7
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '23',
        img: img8
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '20',
        img: img9
    },
    {
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '95',
        img: img10
    }
];

 export const ProductsData = [
    {
        type: 'Skin Care',
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '25',
        img: img1
    },
    {
        type: 'Skin Care',
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '30',
        img: img2
    },
    {
        type: 'Skin Care',
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '35',
        img: img3
    },
    {
        type: 'Foundations',
        name: 'SKIN',
        detail: 'Super Skin Care',
        price: '40',
        img: img4
    },
    {
        type: 'Conditioners',
        name: 'Skin Care',
        detail: 'Super Skin Care',
        price: '50',
        img: img5
    },
    {
        type: 'Conditioners',
        name: 'Skin Care',
        detail: 'Super Skin Care',
        price: '65',
        img: img6
    },
    {
        type: 'Skin Care',
        name: 'Conditioners',
        detail: 'Super Skin Care',
        price: '29',
        img: img7
    },
    {
        type: 'Foundations',
        name: 'Foundations',
        detail: 'Super Skin Care',
        price: '23',
        img: img8
    },
    {
        type: 'Conditioners',
        name: 'Conditioners',
        detail: 'Super Skin Care',
        price: '20',
        img: img9
    },
    {
        type: 'Foundations',
        name: 'Foundations',
        detail: 'Super Skin Care',
        price: '95',
        img: img10
    }
]