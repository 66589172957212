import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './slider.css';
import { SliderProducts }  from '../../data/products';

const Slider = () => {
  return ( 
    <div className="s-container">
      <Swiper
      modules={[Pagination, Navigation ]} 
      className="myswiper"
      navigation={true}
      loopFillGroupWithBlank={true}
       slidesPerView={3} spaceBetween={40} slidesPerGroup={1} loop={true}
       breakpoints={{
        0: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 2,
        },
        776: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        }
       }}
       >
        {SliderProducts.map((slide, i) => (
          <SwiperSlide>
           <div className="left-s">
            <div className="name">
              <span>{slide.name}</span>
              <span>{slide.detail}</span>
            </div>
            <span>${slide.price}</span>
            <button className='btn btn-primary'>Shop Now</button>
           </div>
           <img src={slide.img} alt='product' className='img-r'/>
            </SwiperSlide>
        
        ))}
      </Swiper>
    </div>  
  )
}

export default Slider;