import React from 'react';
import css from './Testimonial.module.css';
import Hero2 from 'D:/skinprojecct/src/assets/Hero2.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TestimonialsData } from 'D:/skinprojecct/src/data/testimonials';

const Testimonial = () => {
  return (
    <div className={css.Testimonial}>
      <div className={css.wrapper}>
        <div className={css.container}>
        <span>Top Rated</span>
        <span>Seedly say has suitable disposal and boy.Exercise joy men children rejoiced</span>
      </div>
      
      <img src={Hero2} alt=""/>
      <div className={css.container}>
        <span>100K</span>
        <span>Happy Customers with us</span>
      </div>
      </div>
{/* Testimonial carousal */}
<div className={css.reviews}>Reviews</div>
<div className={css.carousel}>
    <Swiper
    slidesPerView={3}
    slidesPerGroup={1}
    spaceBetween={20}
    className={css.tcarousel}
    breakpoints={{
      856: {
         slidesPerView: 3
        },
        640: {
          slidesPerView: 2
        },
        0: {
          slidesPerView: 1
        },

     }}
    >
        {
        TestimonialsData.map((testimonial, i) => (
            <SwiperSlide>
                <div className={css.testimonial}>
                    <img src={testimonial.img} alt="" />
                    <span>{testimonial.text}</span>
                    <hr></hr>
                    <span>{testimonial.name}</span>
                </div>
               
            </SwiperSlide>
        ))}  
    </Swiper>
    <div className="bgpng"></div>
</div>

    </div>
    
  )
}

export default Testimonial;
