import img1 from '../assets/t1.png';
import img2 from '../assets/t2.png';
import img3 from '../assets/t3.png';
import img4 from '../assets/t4.png';
import img5 from '../assets/t5.png';

export const TestimonialsData = [
    {
       name: 'Gb Srivastwa',
       text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
       img: img1
    },
    {
        name: 'A Tiwari',
        text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ',
        img: img2
    },
    {
        name: 'R Srivastwa',
        text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ',
        img: img3
    },
    {
        name: 'Hema Lamba',
        text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ',
        img: img4
    },
    {
        name: 'Juliya ',
        text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ',
        img: img5
    },
 

];