import React from 'react';
import css from 'D:/skinprojecct/src/componant/Footer/Footer.module.css';
import Flogo from 'D:/skinprojecct/src/assets/Flogo.png';
import { 
  BsGeoAlt, BsTelephone, BsEnvelope,
   BsPersonCircle, BsPersonPlusFill,
   BsTwitter, BsPeopleFill, BsFacebook,BsInstagram,BsPlayBtnFill } from "react-icons/bs";


const Footer = () => {
  return (
    <div className={css.cFooterWrapper} id={css.footer}>
      <hr/>
     
      <div className={css.cFooter}>
        <div className={css.logo}>
          <img src={Flogo} alt="" />
          <span>Gb Skin Care</span>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Contact Us</span>
            <span className={css.pngLine}>
              <BsGeoAlt className={css.icon}/>
              <span>Mahadeva Siwan, BIhar, 841226</span>
            </span>
            <span className={css.pngLine}>
              <BsTelephone className={css.icon}/>
              <span>7976800918</span>
            </span>
            <span className={css.pngLine}>
              <BsEnvelope className={css.icon}/>
              <span>kgourav82@gmail.com</span>
            </span>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Account</span>
            <span className={css.pngLine}>
              <BsPersonCircle className={css.icon}/>
              <span>Sign In</span>
            </span>
            <span className={css.pngLine}>
              <BsPersonPlusFill className={css.icon}/>
              <span>Sign Up</span>
            </span>
            
          </div>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Company</span>
            <span className={css.pngLine}>
              <BsPeopleFill className={css.icon}/>
              <span>About Us</span>
            </span>
            
          </div>
        </div>
        <div className={css.social}>
          
            <span className={css.socialDetail}>Follow Us</span>
          
            <span className={css.socialIcon}> <BsTwitter/></span>
            <span className={css.socialIcon}><BsFacebook/></span>
            <span className={css.socialIcon}> <BsPlayBtnFill/></span>
            <span className={css.socialIcon}><BsInstagram/></span>
        </div>
      </div>
      <div className={css.copyRight}>
        <span>CopyRight ©2022 by GB Skin Care</span>
        <span>All Rights Reserved.</span>
      </div>
    </div>
  )
}

export default Footer;
