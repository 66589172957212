import React from 'react';
import Footer from './componant/Footer/Footer';
import Header from './componant/Header';
import Hero from './componant/Hero/Hero';
import Product from './componant/product/Product';
import Slider from './componant/slider/Slider';
import Testimonial from './componant/testimonial/Testimonial';
import Virtual  from './componant/virtual/Virtual';
const App = () => {
  return (
    <div>
      <Header/>
      <Hero/> 
      <Slider/>
      <Virtual/>
      <Product/>
      <Testimonial/>
      <Footer/>
    </div>
  )
}

export default App;

